import React from 'react'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { routes } from 'core/utils/routes'
import { createResourceLabelsCell } from '../common/entity/labels-and-annotations/helpers'
import { IServiceDetailsPageTabs } from './model'
import { getServiceStatus } from './helpers'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { deleteService, listServices } from './new-actions'
import ListContainer from 'core/containers/ListContainer'
import { serviceSelectors } from './selectors'
import useListAction from 'core/hooks/useListAction'
import { GridViewColumn } from 'core/elements/grid/Grid'
import AddWorkloadResourcePage from 'k8s/components/common/entity/AddResourcePage'

import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import { createGridArrayCell } from 'core/elements/grid/cells/GridArrayCell'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'
import { durationBetweenDates } from 'utils/misc'

type ModelDataKey = DataKeys.KubeServices
type SelectorModel = ArrayElement<ReturnType<typeof serviceSelectors>>
type ActionParams = InferActionParams<typeof listServices>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  namespace?: string
  masterNodeClusters: boolean
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']
const defaultParams: Params = {
  clusterId: null,
  masterNodeClusters: true,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Services', listTablePrefs)

const searchTargets = ['name', 'clusterName']

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId, id }) =>
        routes.services.details.path({ clusterId, id, tab: IServiceDetailsPageTabs.Overview }),
    }),
  },
  { key: 'namespace', label: 'Namespace' },
  { key: 'clusterName', label: 'Cluster', width: 'medium' },
  {
    key: 'creationTimestamp',
    label: 'Age',
    formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
  {
    key: 'status',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: getServiceStatus,
    }),
  },
  { key: 'type', label: 'Type' },
  { key: 'clusterIp', label: 'Cluster IP' },
  {
    key: 'externalEndpoints',
    label: 'External Endpoints',
    CellComponent: createGridArrayCell<SelectorModel, SelectorModel['externalEndpoints']>({
      nameFn: ({ hostname = '', ip = '' }) => {
        const ipAddress = ip ? ` (${ip})` : ''
        return `${hostname}${ipAddress}`
      },
    }),
  },
  {
    key: 'ports',
    label: 'Ports',
    CellComponent: createGridArrayCell<SelectorModel, SelectorModel['ports']>({
      nameFn: ({ protocol, port }) => `${port}/${protocol}`,
    }),
  },
  {
    key: 'selectors',
    label: 'Selectors',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
  {
    key: 'labels',
    label: 'Labels',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
  {
    key: 'annotations',
    label: 'Annotations',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: ': ' }),
  },
]

export default function ServicesListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listServices, {
    params,
    requiredParams,
  })
  const data = useAppSelector(serviceSelectors)
  return (
    <>
      <DocumentMeta title="Services" />
      <AddWorkloadResourcePage resourceType="service" addRoute={routes.services.add} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.KubeServices}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes.services.add.path()}
        addText="Add Service"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteService}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
