import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import { isEmpty } from 'ramda'
import { projectAs, isNilOrEmpty } from 'utils/fp'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { makeParamsAllClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import MultiDropdown, { MultiDropdownProps } from 'core/elements/dropdown/MultiDropdown'
import { CancellableDebounceFn, debounce } from 'utils/async'

const defaultParamsAllClusterSelector = makeParamsAllClustersSelector()

export interface MultiClusterDropdownProps extends Omit<MultiDropdownProps<string>, 'items'> {
  onlyMasterNodeClusters?: boolean
  onlyPrometheusEnabled?: boolean
  onlyKubevirtClusters?: boolean
  onlyHealthyClusters?: boolean
  onlyEcoEnabledClusters?: boolean
}

export default function GlobalMultiClusterDropdown({
  label = 'Cluster:',
  onlyMasterNodeClusters = false,
  onlyPrometheusEnabled = false,
  onlyHealthyClusters = true,
  onlyEcoEnabledClusters = true,
  onlyKubevirtClusters = false,
  loading = false,
  compact = true,
  onChange,
  value,
  ...rest
}: MultiClusterDropdownProps) {
  const debounceRef = useRef<CancellableDebounceFn>()
  const defaultParams = useMemo(
    () => ({
      masterNodeClusters: onlyMasterNodeClusters,
      prometheusClusters: onlyPrometheusEnabled,
      healthyClusters: onlyHealthyClusters,
      ecoEnabledClusters: onlyEcoEnabledClusters,
      kubevirtClusters: onlyKubevirtClusters,
    }),
    [
      onlyMasterNodeClusters,
      onlyPrometheusEnabled,
      onlyHealthyClusters,
      onlyEcoEnabledClusters,
      onlyKubevirtClusters,
    ],
  )

  // We don't need to call the cluster list actions since they are preloaded in AuthContainer
  const allClusters: CombinedClusterSelector[] = useSelectorWithParams(
    defaultParamsAllClusterSelector,
    defaultParams,
  )
  const options = useMemo(() => {
    return [...projectAs({ label: 'name', value: 'uuid' }, allClusters)]
  }, [allClusters])

  const debouncedOnChange = useCallback(
    (selectedValues) => {
      debounceRef.current?.cancel()
      debounceRef.current = debounce(() => {
        onChange(selectedValues)
      }, 1000)
      debounceRef.current()
    },
    [onChange],
  )

  useEffect(() => {
    if (isNilOrEmpty(value) && !isEmpty(options)) {
      const [firstOption] = options
      onChange([firstOption.value])
    }
  }, [])

  return (
    <MultiDropdown
      preventUnselectLast
      compact={compact}
      items={options}
      onChange={debouncedOnChange}
      label={label}
      loading={loading}
      value={value}
      {...rest}
    />
  )
}
