import { makeStyles } from '@material-ui/styles'
import { listTablePrefs, allKey, TablePrefsParams } from 'app/constants'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import EventsTypePicklist from 'app/plugins/infrastructure/components/clusters/EventsTypePicklist'
import React, { useMemo, useEffect } from 'react'
import { calculateAge } from 'utils/misc'
import Theme from 'core/themes/model'
import { emptyArr, ensureArray, isNilOrEmpty, switchCase } from 'utils/fp'
import ListContainer from 'core/containers/ListContainer'
import { equals, pick } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import { clusterEventsSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import useListAction from 'core/hooks/useListAction'
import { ArrayElement } from 'core/actions/Action'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import {
  listClusterEvents,
  deleteClusterEvent,
} from 'app/plugins/infrastructure/components/clusters/newActions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridFilterSpec } from 'core/elements/grid/hooks/useGridFiltering'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import InferActionParams from 'core/actions/InferActionParams'

type ModelDataKey = DataKeys.Events
type SelectorModel = ArrayElement<ReturnType<typeof clusterEventsSelector>>
type ActionParams = InferActionParams<typeof listClusterEvents>

type Params = {
  clusterId: string | string[]
  type: string
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']
const defaultParams: Params = {
  clusterId: null,
  type: allKey,
}
interface StyleProps {
  noMargin?: boolean
}

interface Props {
  entity: any | any[]
  loading?: boolean
  noMargin?: boolean
  entityClusterIds?: string | string[]
}

export const getEventTypeStatus = (status) => {
  const variant = switchCase(
    {
      Normal: 'success',
      Warning: 'danger',
    },
    'unknown',
  )(status)
  return { variant, label: status || 'Unknown' }
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  events: {
    marginTop: ({ noMargin }) => (noMargin ? '0px' : '16px'),
  },
}))

const renderAge = (value, entity = {}) => {
  const { deprecatedLastTimestamp } = entity as any
  return calculateAge(value || deprecatedLastTimestamp)
}
const searchTargets = ['type', 'regarding.uid']
const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'type',
    label: 'Type',
    CellComponent: createGridStatusCell({
      dataFn: getEventTypeStatus,
    }),
  },
  { key: 'note', label: 'Message' },
  { key: 'reason', label: 'Reason' },
  { key: 'eventTime', label: 'Age', formatFn: (value, entity) => renderAge(value, entity) },
  { key: 'regarding.kind', label: 'Kind' },
  { key: 'regarding.uid', label: 'From' },
]

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Events', listTablePrefs)

export default function EntityEventsPage({
  entity,
  loading = false,
  noMargin = false,
  entityClusterIds = emptyArr, // This is necessary when entities span several clusters
}: Props) {
  const entityArray = ensureArray(entity)
  const classes = useStyles({ noMargin })
  const { params, updateParams, getParamsUpdater } = usePrefParams(defaultParams)

  useEffect(() => {
    // entityClusterIds prop provided, so get events on all these clusters
    // otherwise just get the clusterId from the first entity
    updateParams({
      clusterId: entityClusterIds?.length ? entityClusterIds : entityArray?.[0]?.clusterId,
    })
  }, [entityClusterIds, entityArray])

  const { message, loading: loadingEvents, reload } = useListAction(listClusterEvents, {
    params,
    requiredParams,
    initialLoadingState: true,
  })

  const allEvents = useSelectorWithParams(clusterEventsSelector, {
    ...params,
    useGlobalParams: false,
  })

  const events = useMemo(() => {
    if (isNilOrEmpty(entity) || isNilOrEmpty(allEvents)) {
      return []
    }
    return allEvents.filter((event) =>
      entityArray.some((entity) => event.regarding?.uid === entity.id),
    )
  }, [entityArray, allEvents])

  const filters = useMemo(
    () => [
      {
        columnKey: 'type',
        FilterComponent: EventsTypePicklist,
        onChange: getParamsUpdater('type'),
      } as GridFilterSpec<SelectorModel, Params, 'type'>,
    ],
    [],
  )

  return (
    <article className={classes.events}>
      <ListContainer<ModelDataKey, SelectorModel>
        showBreadcrumbs={false}
        dataKey={DataKeys.Events}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading || loadingEvents}
        loadingMessage={message}
        onRefresh={reload}
        data={events}
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteClusterEvent}
        filters={filters}
        {...pick(listTablePrefs, params)}
      />
    </article>
  )
}
