import React, { useCallback, useState, useMemo } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import Text from 'core/elements/Text'
import { kubeconfigFileLink } from 'k8s/links'
import DownloadKubeConfigForm from './DownloadKubeConfigForm'
import SimpleLink from 'core/components/SimpleLink'
import ExternalLink from 'core/components/ExternalLink'
import { codeMirrorOptions, onboardingAccessSetup } from 'app/constants'
import { routes } from 'app/core/utils/routes'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import PollingData from 'core/components/PollingData'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import DocumentMeta from 'core/components/DocumentMeta'
import { dissoc } from 'ramda'
import Button from 'core/elements/button'
import Radio from 'core/elements/input/Radio'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { ClusterSelector } from 'app/plugins/infrastructure/components/clusters/model'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { prop } from 'ramda'
import { usersSelector } from 'app/plugins/account/components/userManagement/users/selectors'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 715,
  },
  formCard: {
    marginTop: theme.spacing(0.5),
  },
  submit: {
    display: 'flex',
    marginLeft: theme.spacing(2),
  },
  blueIcon: {
    color: theme.palette.primary.main,
  },
  container: {
    paddingLeft: theme.spacing(),
  },
  detail: {
    marginTop: theme.spacing(),
    display: 'flex',
    flexWrap: 'wrap',
    '& a': {
      margin: theme.spacing(0, 0.5),
    },
  },
  link: {
    display: 'block',
    width: 'fit-content',
  },
  clusterConfig: {
    margin: theme.spacing(2, 0),
  },
  tableContainer: {
    border: 'none',
    marginBottom: theme.spacing(2),
  },
  noClusters: {
    border: 'none',
    '& td': {
      border: 'none',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  yamlContainer: {
    flexFlow: 'column nowrap',
    marginTop: 32,
  },
  codeMirror: {
    '& .CodeMirror': {
      maxHeight: '500px',
      maxWidth: 710,
    },
  },
  redownloadButton: {
    marginTop: theme.spacing(2),
  },
}))

export default function KubeConfigListPage() {
  const [selectedCluster, setSelectedCluster] = useState<ClusterSelector>(null)
  const [downloadedKubeconfigs, setDownloadedKubeconfigs] = useState({})

  const { loading: loadingClusters, reload: reloadClusters } = useListAction(listClusters)
  const clusters = useSelector(clustersSelector)

  const { loading: loadingUsers } = useListAction(listClusters)
  const users = useSelector(usersSelector)
  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { userDetails } = session
  const userId = userDetails?.id
  const user = useMemo(() => {
    return users.find((u) => u.id === userId)
  }, [users, userId])
  const mfaEnabled = user?.mfa?.enabled

  const classes = useStyles()

  const handleDownloadKubeConfig = (cluster) => (kubeconfig) => {
    setDownloadedKubeconfigs({
      ...downloadedKubeconfigs,
      [cluster.uuid]: kubeconfig,
    })
    localStorage.setItem(onboardingAccessSetup, 'true')
  }

  const enableRedownload = useCallback(
    (cluster) => {
      // Strip saved kubeconfig out of state
      // @ts-ignore dissoc doesn't like cluster.uuid as a parameter
      setDownloadedKubeconfigs(dissoc(cluster.uuid, downloadedKubeconfigs))
    },
    [downloadedKubeconfigs],
  )

  return (
    <section className={classes.root}>
      <DocumentMeta title="KubeConfig" breadcrumbs />
      <PollingData
        loading={loadingClusters || loadingUsers}
        onReload={reloadClusters}
        refreshDuration={1000 * 60 * 10}
      />
      <FormFieldCard
        title="Download kubeconfig"
        className={classes.formCard}
        link={
          <div>
            <FontAwesomeIcon className={classes.blueIcon} size="md">
              lock
            </FontAwesomeIcon>{' '}
            <ExternalLink url={kubeconfigFileLink}>KubeConfig & Client Help</ExternalLink>
          </div>
        }
      >
        <div className={classes.container}>
          <Text variant="subtitle2" component="h4" className={classes.clusterConfig}>
            Select a cluster to continue.
          </Text>
          <Table className={classes.tableContainer}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell>
                  <Text variant="caption2">Cluster</Text>
                </TableCell>
                <TableCell>
                  <Text variant="caption2">URL</Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clusters.length === 0 && (
                <TableRow className={classes.noClusters}>
                  <TableCell colSpan={3} align="left">
                    <div>
                      <Text variant="body1">
                        There are no clusters available. You need to{' '}
                        <SimpleLink src={routes.cluster.add.root.path()}>
                          create a cluster
                        </SimpleLink>{' '}
                        first to continue.
                      </Text>
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {clusters.map((cluster = {} as ClusterSelector) => (
                <TableRow key={cluster.uuid} onClick={() => setSelectedCluster(cluster)}>
                  <TableCell padding="checkbox">
                    <Radio checked={!!selectedCluster && selectedCluster.uuid === cluster.uuid} />
                  </TableCell>
                  <TableCell>
                    <Text variant="body2">{cluster.name}</Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2">{cluster.externalDnsName || cluster.masterIp}</Text>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!!selectedCluster && !downloadedKubeconfigs[selectedCluster.uuid] && (
            <DownloadKubeConfigForm
              cluster={selectedCluster}
              onSubmit={handleDownloadKubeConfig(selectedCluster)}
              mfaEnabled={mfaEnabled}
            />
          )}
          {!!selectedCluster && downloadedKubeconfigs[selectedCluster.uuid] && (
            <>
              <Button
                className={classes.redownloadButton}
                onClick={() => enableRedownload(selectedCluster)}
              >
                Redownload kubeconfig
              </Button>
            </>
          )}
        </div>
      </FormFieldCard>
      {!!selectedCluster && (
        <ValidatedForm classes={{ root: classes.yamlContainer }} elevated={false}>
          <CodeMirror
            className={classes.codeMirror}
            id="kubeconfigYaml"
            showCopyButton
            label={selectedCluster.name}
            options={codeMirrorOptions}
            value={downloadedKubeconfigs[selectedCluster.uuid]}
          />
        </ValidatedForm>
      )}
    </section>
  )
}
