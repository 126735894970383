import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import EditEntityLabelsButton from './EditEntityLabelsButton'
import EditEntityAnnotationsButton from './EditEntityAnnotationsButton'
import { isNilOrEmpty } from 'utils/fp'
import {
  Labels,
  Annotations,
} from 'k8s/components/common/entity/labels-and-annotations/LabelsOrAnnotations'
import Text from 'core/elements/Text'

const useStyles = makeStyles((theme: Theme) => ({
  noneText: {
    margin: theme.spacing(2),
    color: theme.components.card.passiveText,
  },
  sectionHeader: {
    padding: '16px 0px',
    display: 'inline-grid',
    gap: 16,
    gridAutoFlow: 'column',
    gridTemplateColumns: 'min-content auto',
    alignItems: 'center',
  },
  testingContainer: {
    padding: '24px 32px',
  },
}))

interface Props {
  entity: any
  resourceType: string
}

export default function LabelsAndAnnotationsSection({ entity, resourceType }: Props) {
  const classes = useStyles()
  const labels = entity?.labels || entity?.metadata?.labels
  const annotations = entity?.annotations || entity?.metadata?.annotations
  return (
    <>
      <div className={classes.sectionHeader}>
        <Text variant="caption1">Labels</Text>
        <EditEntityLabelsButton resourceType={resourceType} entity={entity} />
      </div>
      <div>
        {!isNilOrEmpty(labels) ? (
          <Labels labels={labels} variant="default" showMoreButton ellipsisAt={75} />
        ) : (
          <Text className={classes.noneText} variant="caption1">
            No Labels
          </Text>
        )}
      </div>
      <div className={classes.sectionHeader}>
        <Text variant="caption1">Annotations</Text>
        <EditEntityAnnotationsButton resourceType={resourceType} entity={entity} />
      </div>
      <div>
        {!isNilOrEmpty(annotations) ? (
          <Annotations annotations={annotations} variant="default" showMoreButton ellipsisAt={75} />
        ) : (
          <Text className={classes.noneText} variant="caption1">
            No Annotations
          </Text>
        )}
      </div>
    </>
  )
}
