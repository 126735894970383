import React from 'react'
import { deleteDeployment } from '../deployments/new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import Button from 'core/elements/button'

const protectedNamespaces = [
  'platform9-system',
  'kube-system',
  'pf9-olm',
  'pf9-operators',
  'pf9-monitoring',
]

const DeleteDeploymentDialog = ({ rows: [deployment], onClose }) => {
  const { clusterId, namespace, name, id } = deployment
  const { update, updating } = useUpdateAction(deleteDeployment)
  const allowDelete = !protectedNamespaces.includes(namespace)

  const handleSubmit = async () => {
    await update({ clusterId, namespace, name, id })
    onClose()
  }

  return (
    <ConfirmationDialog
      open
      title="Delete Deployment"
      text={
        allowDelete
          ? `Delete deployment: ${name}?`
          : 'Protected Namespace - Please contact Platform9 for assistance'
      }
      customFooterActions={
        allowDelete ? (
          <>
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="primary" loading={updating}>
              Confirm
            </Button>
          </>
        ) : (
          <Button onClick={onClose} variant="secondary">
            Close
          </Button>
        )
      }
    />
  )
}

export default DeleteDeploymentDialog
