import React, { forwardRef, useMemo, useEffect } from 'react'
import PicklistDefault from 'core/elements/dropdown/AsyncDropdown'
import useListAction from 'core/hooks/useListAction'
import { projectAs } from 'utils/fp'
import { pluck, sortBy, prop, head, sortWith, descend } from 'ramda'
import { useAppSelector } from 'app/store'
import { listSupportedRoleVersions } from 'app/plugins/infrastructure/components/clusters/newActions'
import { supportedRoleVersionsSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { DropdownItemSpec } from 'core/elements/dropdown/DropdownCommonProps'
import { listClusterVersions } from 'app/plugins/infrastructure/components/clusters/cluster-addons/new-actions'
import { clusterVersionsSelector } from 'app/plugins/infrastructure/components/clusters/cluster-addons/selectors'

const Picklist: any = PicklistDefault // types on forward ref .js file dont work well.

interface Props {
  onChange: (key: string) => void
  value: string
  selectFirst?: boolean
  customizeOptionsFn?: (options: DropdownItemSpec<string>[]) => DropdownItemSpec<string>[]
}

const filterDeprecatedVersions = (clusterVersion) => clusterVersion?.phase !== 'Deprecated'

const KubernetesVersionPicklist = forwardRef(
  ({ onChange, value, selectFirst, customizeOptionsFn, ...rest }: Props, ref) => {
    const { loading } = useListAction(listClusterVersions)
    const clusterVersions = useAppSelector(clusterVersionsSelector).filter(filterDeprecatedVersions) // filtering deprecated versions as per discussions with product for now
    const sortedClusterVersions: any[] = useMemo(
      () => sortWith([descend(prop('kubeVersion'))])(clusterVersions),
      [clusterVersions],
    )

    const allOptions = useMemo(() => {
      return sortedClusterVersions.map((clusterVersion) => ({
        label: clusterVersion?.version,
        value: clusterVersion?.version,
      }))
    }, [sortedClusterVersions])

    const versions: string[] = useMemo(() => pluck('version')(sortedClusterVersions), [
      sortedClusterVersions,
    ])

    useEffect(() => {
      if (selectFirst && sortedClusterVersions?.length) {
        if (!value || !versions.includes(value)) {
          const latestVersion = head(sortedClusterVersions)?.version // get the newest (largest value) version
          onChange(latestVersion)
        }
      }
    }, [sortedClusterVersions, selectFirst, value])

    const options = useMemo(
      () => (customizeOptionsFn ? customizeOptionsFn(allOptions) : allOptions),
      [allOptions, customizeOptionsFn],
    )

    return (
      <Picklist
        {...rest}
        value={value}
        onChange={onChange}
        loading={loading}
        ref={ref}
        items={options}
      />
    )
  },
)

export default KubernetesVersionPicklist
